
import React, { useContext, useState } from "react"
import styled from "@emotion/styled"
import { Global, css } from "@emotion/react";
import ExibitList from "../../components/ExibitList"
import CustomAccordion from "../CustomAccordion/accordion.jsx"
import AwardsList from "../../components/AwardsList"


import { renderRichText } from 'gatsby-source-contentful/rich-text'

/* import { PiDotOutlineDuotone, PiLinkBold } from 'react-icons/pi';
 */

//PiFilePdf
//PiLinkBold

/* import { getImage, GatsbyImage } from "gatsby-plugin-image"
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry"
 */

const AboutMe = ({ title, content, additionalContent, works }) => {

    const GlobalStyles = css`
    body {
        p {
            max-width:100%;
            text-align:justify;
            margin-bottom:0;
            text-align:left;
            width:100%;
            margin-top: 5px;
        }

        a {
            color: #333;
        }
    }
    `

    const breakpoints = {
        s: 576,
        m: 768,
        l: 992,
        xl: 1200,
    }

    const Holder = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    margin: 0 auto;
    padding:1rem;
    margin-bottom:70px;

    .accordion {
        $accordion-primary: #444444;
        $accordion-orange: #fd7a00;
    
        color: #333;
        width: 100%;
        position: relative;
        
    
        &::before {
            content: "";
            background-color: $accordion-orange;
            position: absolute;
            border-top-left-radius: 10px;
            border-bottom-left-radius: 10px;
            margin-left: -5px;
            width: 10px;
            height: 100%;
        }
    
        button {
            
            outline: none;
            padding: 0;
            border: none;
            text-align: left;
            width: 100%;
            transition: 0.4s;
            text-decoration: none;
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-size: 24px;
            cursor: pointer;
            background-color: transparent;
            border-top: 1px solid #ddd;
            color:##333;
            font-weight: 700;
            h3 {
              margin-bottom: 0;
              
              padding-bottom:10px;
              width: fit-content;
              padding: 5px 10px;
              color:#333;
            }
            &.active,
            &:hover {
                
              
                h3 {
                  border-bottom: 1px solid;
                 
                  
                }
                
              
                color: #333;
    
                .arrow::before,
                .arrow::after {
                    background: #333;
                }
            }
        }
    
        .accordion-panel {
            padding: 0;
            margin-top: 10px;
            color: #444444;
            overflow: hidden;
            transition: max-height 0.3s ease-in-out;
        }
    
        .arrow {
            
    
            &:before,
            &:after {
                position: relative;
                content: "";
                display: block;
                width: 10px;
                height: 2px;
                background: $accordion-orange;
                transition: 0.3s ease-in-out;
            }
    
            &:before {
                transform: rotate(45deg);
            }
    
            &:after {
                left: 7px;
                top: -2px;
                transform: rotate(-45deg);
            }
        }
    
        &.collapsed {
            .accordion-panel {
                max-height: 0;
            }
        }
    
        &.uncollapsed {
            .accordion-panel {
                max-height: 100%;
            }
    
            .arrow::before {
                transform: rotate(-45deg);
            }
    
            .arrow::after {
                transform: rotate(45deg);
            }
        }
    }

    .holder-section-sx {
        max-width: 70%;
        display:flex;
        justify-content:start;
        flex-direction: column;
        align-items: start;

        p {
            text-align:justify;
        }
    }

    .holder-section {
        display:flex;
        justify-content:flex-start;
        width:100%;
        margin-top:1rem;
        
        @media (max-width: ${breakpoints.s}px) {
            flex-direction: column;
        }

        > div {
            width:auto;
            width: fit-content;
            margin: 0;
         
            border-color:var(--color-primary);
            padding: 0 1rem;
        }

        > div.uncollapsed {
            border-right:1px solid #ddd; 

            button {
                h3 {
                    background-color: #333;
                    color:#fff;
                }
            }
        }
    }
`;

    console.log("additionalContent", additionalContent[0].contentList);

    console.log("worksworks", works);

    /* const data = (exhibitions, publications, awards) => {
        let dataArray = [
            {
                title: 'Exhibitions',
                content: exhibitions,
                contentType: "exhibitions",
                disabled: false,
            },
            {
                title: 'Publications',
                content: publications,
                contentType: "publications",
                disabled: false,
            },
            {
                title: "Awards",
                content: awards,
                contentType: "awards",
                disabled: false,
            },
            {
                title: "Description",
                content: description,
                contentType: "description",
                disabled: false,
            },
        ];

        return dataArray.filter(el => el.content !== null);
    }; */





    return (
        <>
            <Global styles={GlobalStyles} />
            <Holder>
                <div className="holder-section-sx">
                    <h2>{title}</h2>
                    {content && renderRichText(content)}

                </div>
                <div className="holder-section">
                    <CustomAccordion
                        key={"exhibitions"}
                        title={"Exhibitions"}
                        content={
                            <ExibitList data={works}>

                            </ExibitList>
                        }
                    />
                    <CustomAccordion
                        key={"Awards"}
                        title={"Awards"}
                        content={
                            <AwardsList data={additionalContent[0].contentList}></AwardsList>
                        }
                    />

                    

                </div>
            </Holder>
        </>

    )
}

export default AboutMe
