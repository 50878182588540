
import React, { useContext, useState } from "react"
import styled from "@emotion/styled"

import { renderRichText } from 'gatsby-source-contentful/rich-text'
import { MdElderlyWoman } from "react-icons/md";

/* import { PiDotOutlineDuotone, PiLinkBold } from 'react-icons/pi';
 */

//PiFilePdf
//PiLinkBold

/* import { getImage, GatsbyImage } from "gatsby-plugin-image"
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry"
 */

const AwardsList = ({ data }) => {




    const Holder = styled.div`
        display: flex;
       
        flex-direction: column;
        justify-content: start;
        align-items: start;
        width: 100%;
        margin: 0 auto;

        li {
            margin-bottom: calc(var(--space-2) / 2);
            list-style:circle;
            span:first-of-type{
                margin-right:calc(var(--space-2) / 2);
               
                border-color:var(--color-primary);
                font-weight:600;
            }
            span:last-child{
                font-weight:400;
                font-size:13px;
                display:block;
                
            }

            a {
                color:var(--color-text);
                text-decoration:none;
            }
        }

        
        
`;



    /* let mainData = []; */
    /* data.forEach(el => {
        if (el.node.exhibitions) {
            el.node.exhibitions.forEach(exibit => {
                if (mainData.hasOwnProperty(el.node.id)) {
                    mainData[el.node.id].list.push(exibit);
                } else {
                    mainData[el.node.id] = {
                        title: el.node.title,
                        list: [exibit]
                    };
                }
            });
        }

    }); */
    /* const newData =  data.filter((work) => {
        return work.node.exhibitions ?  { exhibitions:data.filter(el=>el.node.id==work.node.id).map(el2=>el2.node.exhibitions), title: work.node.title, id: work.node.id} : false;
    }) */
    //console.log("newData",newData)
    //console.log("mainData", mainData,"  to array",Object.entries(mainData));


    /* const data = (exhibitions, publications, awards) => {
        let dataArray = [
            {
                title: 'Exhibitions',
                content: exhibitions,
                contentType: "exhibitions",
                disabled: false,
            },
            {
                title: 'Publications',
                content: publications,
                contentType: "publications",
                disabled: false,
            },
            {
                title: "Awards",
                content: awards,
                contentType: "awards",
                disabled: false,
            },
            {
                title: "Description",
                content: description,
                contentType: "description",
                disabled: false,
            },
        ];

        return data.filter(el => el.content !== null);
    };
 */


    return (
        <>

            <Holder>
                <h2>Awards</h2>
                <ul>
                {
                    data.map(function (el, i) {
                        return <li className="item" key={i}>
                            <span>{el.title}</span>
                            <div>
                                <span>{el.subTitle}</span>
                                <span>{el.label}</span>
                            </div>

                        </li>
                    })
                }
                </ul>


            </Holder>
        </>

    )
}

export default AwardsList
