import * as React from "react"
import { useContext } from "react"
import { Link } from "gatsby"
import { graphql } from "gatsby"
import LayoutContext from "gatsby-theme-photography-portfolio/src/context/LayoutContext"//"../context/LayoutContext"
import Sidebar from "../components/SidebarCms"
import { Main, SidebarButtonWrapper,FooterHolder } from "../utils/styles"//"../utils/styles"
import { CloseIcon, HamburgerIcon } from "gatsby-theme-photography-portfolio/src/components/ui/icons"//"../components/ui/icons"
import MiniHeader from "../components/MiniHeader"
import Seo from "../components/seo"
import AboutMe from "../components/AboutMe"
import ExibitList from "../components/ExibitList"


const Page = ({ data: { contentfulPage, allContentfulWorks } }) => {
  console.log("data!!", contentfulPage,allContentfulWorks);
  //getInfo(allContentfulWorks)
  const { sidebarVisible, toggleSidebar } = useContext(LayoutContext)

  const works = allContentfulWorks.edges;


  return (
    <>
      <Seo title={contentfulPage.title} />
      <SidebarButtonWrapper>
        {sidebarVisible ? (
          <CloseIcon onClick={toggleSidebar} />
        ) : (
            <HamburgerIcon onClick={toggleSidebar} />
          )}
      </SidebarButtonWrapper>
      <Sidebar categories={works} />

      <Main sidebarVisible={sidebarVisible} onClick={sidebarVisible?toggleSidebar:null} >
        <MiniHeader sidebarVisible={sidebarVisible}>
        </MiniHeader>

            <AboutMe title={contentfulPage.title} content={contentfulPage.content} additionalContent={contentfulPage.additionalContent} works={works} /> 
            {/* <ExibitList data={works} />         */}    

        <FooterHolder
          style={{
            marginTop: `var(--space-5)`,
            fontSize: `var(--font-sm)`,
          }}
        >
          <div className="copy-footer">© Copyright <span>NADIA SHIRA COHEN 2023</span> </div>
          <div className="gerenza">Built by <span>Emiliano Pallini</span></div>
        </FooterHolder>
      </Main>
      
    </>

  )

}

export const data = graphql`
  query pageQuery($id: String) {
    contentfulPage(id: { eq: $id }) {
        id
        content {
          raw
        }
        additionalContent{
            id
            title
            contentList {
              id
              title
              subTitle
              label
            }
          }
        title
        type
    }
    allContentfulWorks(sort: {fields: order}) {
      edges {
        node {
          title
          archived
          id
          awards {
            id
            name
            url
          }
          exhibitions {
            id
            location
            name
            url
          }
          publications {
            id
            title
            magazine
            url
            pdf {
              file {
                url
              }
            }
          }
        }
      }
    }
    
    
  }
`



export default Page
